import { observer } from "mobx-react-lite";
import Image from "next/image";
import Link from "next/link";
import { localeStore } from "@/stores";
import { findLocale, locales } from "@/locales";
import { useEffect, useRef, useState } from "react";
import Head from "next/head";
import classNames from "classnames";

const Navbar = ({ locale, bg, infoPage, }: { locale: string; bg: boolean; infoPage?: boolean; }) => {
  const langRef = useRef(null);
  const currRef = useRef(null);
  const [langShown, setLangShown] = useState(false);
  const [currencyShown, setCurrencyShown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (langRef.current && !langRef.current.contains(event.target)) {
        setLangShown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [langShown]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (currRef.current && !currRef.current.contains(event.target)) {
        setCurrencyShown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [currencyShown]);

  //TODO: Move the dropdowns to their own component and fix this sphagetti code

  useEffect(() => {
    const localeData = findLocale(locale);
    localeStore.setLocale(localeData);
  }, [locale]);


  const websiteSchemaJSON = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Rent80",
    alternateName: "Rent80",
    legalName: "Rent80",
    url: "https://www.rent80.com/",
    sameAs: [
      "https://www.facebook.com/rent80rentals/",
      "https://twitter.com/rent80rentals",
      "https://www.youtube.com/@rent8026",
      "https://www.rent80.com/",
      "https://www.instagram.com/rent80rentals/",
    ],
  };
  const orgSchemaJSON = {
    "@context": "https://schema.org",
    "@type": "OnlineBusiness",
    name: "Rent80",
    url: "https://www.rent80.com",
    logo: "https://www.rent80.com/logo.jpeg",
    sameAs: ["https://rent80.com"],
    description:
      "Rent80 compare the rental car prices from all major car rental providers such as sixt, avis, dollar, budget and kayak. Rent80 great selection and prices on rental cars.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "43 Al Fahidi St",
      addressLocality: "Dubai",
      addressCountry: "AE",
      addressRegion: "Al Fahidi",
      postalCode: "122002",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingCount: 800,
      ratingValue: 4.2,
    },
    contactPoint: {
      "@type": "ContactPoint",
      email: "hello@rent80.com",
      // telephone: "--------", // FIXME: Add phone number
    },
    numberOfEmployees: {
      "@type": "QuantitativeValue",
      value: 25,
    },
    foundingDate: "2021-01-01T10:10:00.000Z",
    // vatID: "",
    // taxID: "",
  };
  return (
    <>
      <Head>
        {process.browser && (
          <>
            {" "}
            <script type="application/ld+json">
              {JSON.stringify(websiteSchemaJSON)}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(orgSchemaJSON)}
            </script>
          </>
        )}
      </Head>
      <nav className={classNames("hidden relative z-10 items-center w-full lg:flex h-[81px]", { "navbar-gradient": bg })}>
        <div className="flex justify-between items-center mx-auto w-full max-w-7xl py-auto lg:py-6">
          <Link href={"/"}>
            <Image
              src="/logo.svg"
              height={47}
              width={166}
              alt="Rent80 logo"
              className="hidden lg:block"
            />
          </Link>
          {bg && (
            <div className="flex items-center justify-center gap-[10px] z-[800]">
              <p
                className="language-btn-gradient py-[14px] px-3 text-white rounded-lg text-[16px] flex items-center cursor-pointer select-none"
              >
                {localeStore.cc || "USA"}&nbsp; English
              </p>
              {langShown && (
                <div
                  className="absolute bg-[#fff] top-[5rem] rounded-xl translate-x-[-10rem]"
                  ref={langRef}
                >
                  <div className="my-4 max-h-[60vh] overflow-y-scroll scrollbar-thin scrollbar-track-[#edeff4] scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-thumb-[#d8dfee]">
                    {locales.map((data, key) => (
                      <div
                        key={key}
                        className="w-[300px] flex items-center mx-4 border-b border-[#E5EAF6] py-[0.5rem] cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          className="w-[16px] h-[16px] rounded-[6px] border-[2px] border-[#c4c4c4] cursor-pointer mr-1"
                          defaultChecked={data.code === localeStore.code}
                          onChange={() => { }}
                        />
                        <div className="flex justify-between items-center w-full">
                          <p className="py-[14px] px-3 text-[16px] cursor-pointer select-none">
                            {data.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <p className="language-btn-gradient py-[14px] px-3 text-white rounded-lg text-[16px] flex items-center cursor-pointer select-none">
                {localeStore.currency || "USD"}
              </p>
            </div>
          )}
        </div>
      </nav>
      <nav
        className={classNames("block w-full lg:hidden", { "navbar-gradient": infoPage })}>
        <div className="max-w-7xl mx-auto flex justify-start py-5 w-full px-[13px]">
          <Link href={"/"}>
            <img
              src="/logo.svg"
              height={18}
              width={78}
              alt="Rent80 logo"
              className="block lg:hidden"
            />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default observer(Navbar);
